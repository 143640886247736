/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.input-disabled-color::placeholder {
    color: #4f4f4f !important;
}

.input-active-color::placeholder {
    color: #333333 !important;
}
.mat-form-field.mat-form-field-appearance-outline
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-outline {
    background-color: white !important;
}

.flex-zafari {
	margin: -20px -20px;
}

.flex-zafari > button {
	margin: 20px 20px;
}

@media (max-width: 600px) {
	.flex-zafari {
		margin: -20px 0px;
	}
	
	.flex-zafari > button {
		margin: 20px 0px;
	}
}



.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.custom-scroll::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
	background-color: #edeff5;
	border-radius: 20px;
}

.mat-stroked-button:not(.mat-button-disabled) {
	border-color: #d52b1e !important ;
}

.detalle {
	.mat-stroked-button:not(.mat-button-disabled) {
			border-color: #0097A9 !important ;
	}
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.mat-slide-toggle {
	height: 28px !important ;
}

.mat-slide-toggle-bar {
	width: 52px !important;
	height: 32px !important;
	border-radius: 35px !important;
}

.mat-slide-toggle-thumb {
	height: 28px !important;
	width: 28px !important;
}

.mat-slide-toggle-thumb-container {
	width: 28px !important;
	height: 28px !important;
	top: 2px !important;
	left: 2px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
	transform: translate3d(20px, 0, 0) !important;
}

.mat-sort-header-container {
    letter-spacing: 0.8px !important;
}

.mat-datepicker-toggle-default-icon {
	@apply text-primary-red-02 #{'!important'};
}

.to-top {
    bottom: 0;
    cursor: pointer;
    margin: 0 8px 12px 0;
    position: fixed;
    right: 0;
    opacity: 0;
  }
  
.show-scrollTop {
  opacity: 1 !important;
  transition: all 0.2s ease-in-out;
}